define('ember-cli-swiper/components/swiper-container', ['exports', 'ember-cli-swiper/templates/components/swiper-container'], function (exports, _swiperContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var keys = Object.keys;

  var assign = Object.assign || Ember.assign;

  var EMBER_CLI_SWIPER_OPTIONS = ['options', 'navigation', 'registerAs', 'vertical', 'centered', 'updateFor', 'afterSwiperInit', 'currentSlide'];

  exports.default = Ember.Component.extend({
    layout: _swiperContainer.default,
    classNames: ['swiper-container'],

    /**
     * Swiper Instance
     * @private
     * @type {Boolean}
     */
    _swiper: false,

    /**
     * Proxies `Swiper.activeIndex`
     * @public
     * @type {Number}
     */
    currentSlide: 0,

    /**
     * Compared against `currentSlide`
     * @private
     * @type {Number}
     */
    _currentSlideInternal: 0,

    /**
     * Abstraction to invoke `Swiper.update`
     * @public
     * @type {String}
     */
    updateFor: '',

    /**
     * Compared against `updateFor`
     * @private
     * @type {String}
     */
    _updateForInternal: '',

    /**
     * Render navigation controls
     * @public
     * @type {Boolean}
     */
    hasNavigation: Ember.computed.or('options.navigation', 'navigation'),

    /**
     * Render pagination controls
     * @public
     * @type {Boolean}
     */
    hasPagination: Ember.computed.or('options.pagination', 'pagination'),

    /**
     * Single Attribute options
     * @public
     * @type {Object}
     */
    options: Ember.computed(function () {
      return {};
    }),

    /**
     * Get Swiper options from attributes
     * @private
     * @return {Object}
     */
    _getOptions: function _getOptions() {
      var _Ember;

      var attrs = (_Ember = Ember).getProperties.apply(_Ember, [this].concat(_toConsumableArray(keys(this.attrs))));
      var options = assign({}, this.get('options'), attrs);

      /*
       Automatically configure pagination set to `true`
       */
      if (options.pagination === true) {
        options.pagination = '#' + this.get('elementId') + ' > .swiper-pagination';
      }

      if (options.pagination) {
        options.paginationClickable = true; // paginated must be clickable
      }

      if (options.navigation) {
        (true && Ember.warn('ember-cli-swiper option `nextButton` is ignored while `navigation` true', !options.nextButton, { id: 'ember-cli-swiper.next-button-with-navigation' }));
        (true && Ember.warn('ember-cli-swiper option `prevButton` is ignored while `navigation` true', !options.prevButton, { id: 'ember-cli-swiper.prev-button-with-navigation' }));


        options.nextButton = '.swiper-button-next';
        options.prevButton = '.swiper-button-prev';
      }

      if (options.vertical) {
        (true && Ember.warn('ember-cli-swiper option `direction` is ignored while `vertical` true', !options.direction, { id: 'ember-cli-swiper.direction-with-virtical' }));


        options.direction = 'vertical';
      }

      if (options.centered) {
        (true && Ember.warn('ember-cli-swiper option `centeredSlides` is ignored while `centered` true', !options.centeredSlides, { id: 'ember-cli-swiper.centered-with-centered-slides' }));


        options.centeredSlides = true;
      }

      /*
       Remove component-only
       configuration options from Swiper options
       */
      keys(options).forEach(function (k) {
        return EMBER_CLI_SWIPER_OPTIONS.includes(k) && delete options[k];
      });

      return options;
    },
    forceUpdate: function forceUpdate(updateTranslate) {
      this.get('_swiper').update(updateTranslate === undefined ? false : updateTranslate);
      this.get('_swiper').slideTo(this.get('currentSlide'));
    },
    slideChanged: function slideChanged(swiper) {
      var index = this.get('loop') ? Ember.$(swiper.slides).filter('.swiper-slide-active').attr('data-swiper-slide-index') : swiper.activeIndex;

      this.set('_currentSlideInternal', index);
      this.set('currentSlide', index);
      this.sendAction('onChange', swiper.slides[swiper.activeIndex]);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      /*
       Data-down Swiper slide activation
       */
      if (this.get('currentSlide') !== this.get('_currentSlideInternal')) {
        var index = this.get('currentSlide');

        if (this.get('loop')) {
          var swiper = this.get('_swiper');
          index = Ember.$(swiper.slides).filter('[data-swiper-slide-index=' + this.get('currentSlide') + ']').prevAll().length;
        }

        this.get('_swiper').slideTo(index);
        this.set('_currentSlideInternal', this.get('currentSlide'));
      }

      /*
       Trigger `update()` of swiper
       */
      if (this.get('updateFor') !== this.get('_updateForInternal')) {
        Ember.run.once(this, this.get('_swiper').update);
        this.set('_updateForInternal', this.get('updateFor'));
      }
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.set('registerAs', this);

      this.set('_swiper', new Swiper(this.element, this._getOptions())).on('onSlideChangeEnd', this.slideChanged.bind(this)).slideTo(this.get('currentSlide'));

      this.sendAction('afterSwiperInit', this);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('_swiper')) {
        this.get('_swiper').off('onSlideChangeEnd');
        this.get('_swiper').destroy();
        this.set('_swiper', null);
      }
    }
  });
});